<div>
    <div class="pdf-header">
        <button class="btn btn-sm d-flex align-items-center btn-outline-primary">
            <span>Switch to New Tab</span>
        </button>
        <label>{{ pdfSource?.name }}</label>
    </div>
    <div class="slider-container">
        <ngx-slider [(value)]="zoomLevel" [options]="zoomSlider" (userChange)="updateZoomLevel($event)"></ngx-slider>
    </div>
    <pdf-viewer *ngIf="base64DocumentData" #pdfviewer [src]="base64DocumentData" [render-text]="true"
        [original-size]="true" [show-all]="true" [fit-to-page]="true" [zoom]="zoomLevel"
        style="display: block;  min-height: 120px; height: calc(100vh - 25.5rem)"
        (page-rendered)="pageRendered($event)">
    </pdf-viewer>
</div>