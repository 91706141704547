<section>
    <div class="modal fade" id="upload-document-modal" tabindex="-1" aria-labelledby="uploadDocumentModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content uploadPopup">
                <div class="modal-header">
                    <h5 class="modal-title popupTitle" id="uploadDocumentModalLabel">Select/Upload File to Msuite Storage</h5>
                </div>
                <div class="modal-body modalBody">
                    <div class="buttonsLayout">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Upload a file</span>
                                <input type="file" multiple (change)="handleFileSelection($event)" #fileInput
                                    class="d-none" accept=".pdf">
                                <button type="button" (click)="onChooseFile(fileInput)"
                                    class="btn btn-primary btn-sm ms-2">Choose File</button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-primary btn-sm"
                                    [disabled]="uploadInProgress || !this.fileToUpload.length"
                                    (click)="handleFileUploadToStorage()">Upload</button>
                            </div>
                        </div>
                        <div class="divUpoadedfileList">
                            <span class="fileName" *ngFor="let fileName of fileNameToUpload">{{ fileName }}</span>
                        </div>
                    </div>
                    <div class="mb-2">
                        <h6 class="">File Listing for {{loanHeaderDetails?.loan_Number}} \ {{folderName}}</h6>
                    </div>
                    <div class="fileGridContainer">
                        <div class="divTable">
                            <table class="table datatable" *ngIf="!uploadInProgress">
                                <thead>
                                    <tr>
                                        <th class="text-start colHeading">File Name</th>
                                        <th class="text-start">Created Date</th>
                                        <th class="text-end">Size</th>
                                        <th class="text-end">
                                            <ng-container *ngIf="multiSelect">
                                                <input class="form-check-input selectHeading" type="checkbox" value=""
                                                    id="flexCheckIndeterminate" [checked]="allSelected"
                                                    (change)="selectAllFiles($event)" />
                                            </ng-container>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of fileListGrid">
                                        <td class="text-start mx-4 gridText">
                                            <div>{{file.name}}</div>
                                        </td>
                                        <td class="text-start mx-4 gridText">
                                            <div>{{formatDate(file.createdOn)}}</div>
                                        </td>
                                        <td class="text-center mx-4 gridText">
                                            <div>{{file.size}}KB</div>
                                        </td>
                                        <td class="text-end">
                                            <ng-container *ngIf="multiSelect">
                                                <input class="form-check-input cursor-pointer" type="checkbox"
                                                    [checked]="file.selected"
                                                    (change)="handleFileSelectionChange(file, $event)" />
                                            </ng-container>
                                            <ng-container *ngIf="!multiSelect">
                                                <input type="radio" name="fileToUpload" [checked]="file.selected"
                                                    (change)="handleFileSelectionChange(file, $event)" />
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="uploadInProgress" class="d-flex align-items-center justify-content-center">
                            <p-progressSpinner ariaLabel="loading" class="customClass"></p-progressSpinner>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal"
                        [disabled]="!selectedFiles.length" (click)="handleOnSelectDocument()">Select File</button>
                    <button type="button" class="btn btn-outline-primary btn-sm" data-bs-dismiss="modal"
                        (click)="handleOnCancelPopup()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</section>