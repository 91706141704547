import { ActionType, createAction, props } from '@ngrx/store';

export const GET_DOCUMENT_BY_STATEMENT_ID = 'GET_DOCUMENT_BY_STATEMENT_ID';
export const GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS = 'GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS';
export const GET_DOCUMENT_BY_STATEMENT_ID_FAILURE = 'GET_DOCUMENT_BY_STATEMENT_ID_FAILURE';

export const GetDocumentByStatementId = createAction(GET_DOCUMENT_BY_STATEMENT_ID, props<{ statementId: string, freshFetch?: boolean }>());
export const GetDocumentByStatementIdSuccess = createAction(GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS, props<{ statementId: string, data: string }>());
export const GetDocumentByStatementIdFailure = createAction(GET_DOCUMENT_BY_STATEMENT_ID_FAILURE, props<{ statementId: string, errors?: string[], message: string }>());


export type SummaryActions =
    | ActionType<typeof GetDocumentByStatementId>
    | ActionType<typeof GetDocumentByStatementIdSuccess>
    | ActionType<typeof GetDocumentByStatementIdFailure>