import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApplicationRoute, RouteTo } from "src/app/app-routing.module";
import { BuisnessType, LoanHeaderDetails, UpdateLoanHeaderDetails } from "src/app/models/loans";
import { BankStatementExtractionService } from "src/app/service/BankStatementExtractionService/bank-statement-extraction.service";

@Component({
  selector: "app-loan-header-details",
  templateUrl: "./loan-header-details.component.html",
  styleUrl: "./loan-header-details.component.css",
})
export class LoanHeaderDetailsComponent implements OnChanges {
  @Input() loanDetails: LoanHeaderDetails | undefined;
  @Input() isEditable: boolean | undefined;
  @Input() buisnessTypes: BuisnessType[] = [];

  @Output() sendUpdatedLoanDetails: EventEmitter<LoanHeaderDetails | undefined> = new EventEmitter();

  loanSummaryDetailsForm: FormGroup;
  isEditing = false;

  constructor(
    private bankStatementExtractionService: BankStatementExtractionService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.loanSummaryDetailsForm = this.fb.group({
      id: [""],
      loan_Number: ["", Validators.required],
      borrower_Name: ["", Validators.required],
      business_Name: ["", Validators.required],
      business_Type: ["", Validators.required],
      no_Of_Statements: [0, Validators.required],
      businessType_Code: ["", Validators.required],
    });
  }

  onInputChange(event: Event, formControlName: string) {
    const target = event.target as HTMLInputElement;
    this.loanSummaryDetailsForm.get(formControlName)?.setValue(target.value);
    this.loanSummaryDetailsForm.get(formControlName)?.markAsTouched();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  showErrorMsg(formControlName: string): boolean {
    const control = this.loanSummaryDetailsForm.get(formControlName);
    return !!control?.invalid && (control?.touched || control?.dirty) && control?.value === "";
  }

  enableEditingLoanHeader() {
    this.router.navigate([RouteTo(ApplicationRoute.EditLoan)]);
    /* if (this.loanDetails) {
      this.loanSummaryDetailsForm.patchValue(this.loanDetails);
      this.isEditing = true;
    } */
  }

  updateLoanDetailsButton() {
    if (this.loanSummaryDetailsForm.valid && this.loanDetails) {
      const updatedLoanDetails: UpdateLoanHeaderDetails = {
        loanId: this.loanDetails.loan_Id,
        loan_Number: this.loanSummaryDetailsForm.value.loan_Number,
        borrower_1_Name: this.loanSummaryDetailsForm.value.borrower_Name,
        business_Name: this.loanSummaryDetailsForm.value.business_Name,
        no_Of_Statements: this.loanSummaryDetailsForm.value.no_Of_Statements,
        business_Type:
          this.buisnessTypes.length === 1
            ? this.buisnessTypes[0].description
            : this.loanSummaryDetailsForm.value.business_Type,
        businessType_Code:
          this.buisnessTypes.length === 1
            ? this.buisnessTypes[0].code
            : this.loanSummaryDetailsForm.value.businessType_Code,
      };
      this.bankStatementExtractionService.updateLoanDetailsById(updatedLoanDetails).subscribe((response) => {
        if (!response.success) {
          this.toaster.error(response.message, "Error", {
            timeOut: 2000,
            progressBar: true,
            positionClass: "toast-top-center",
          });
        } else {
          this.toaster.success(response.message, "Success", {
            timeOut: 2000,
            progressBar: true,
            positionClass: "toast-top-center",
          });
          this.loanDetails = {
            ...this.loanDetails,
            loan_Id: updatedLoanDetails.loanId,
            loan_Number: updatedLoanDetails.loan_Number,
            borrower_Name: updatedLoanDetails.borrower_1_Name,
            business_Name: updatedLoanDetails.business_Name,
            business_Type: updatedLoanDetails.business_Type,
            no_Of_Statements: +updatedLoanDetails.no_Of_Statements, // convet to int adding +
            businessType_Code: updatedLoanDetails.businessType_Code,
          };
          const loanDetails = JSON.parse(JSON.stringify(this.loanDetails));
          this.sendUpdatedLoanDetails.emit(loanDetails);
          this.isEditing = false;
        }
      });
    } else {
      this.toaster.info("Please fill the required fields", "Alert", {
        timeOut: 2000,
        progressBar: true,
        positionClass: "toast-top-center",
      });
      this.loanSummaryDetailsForm.markAllAsTouched();
    }
  }

  cancelLoanDetailsButton() {
    if (this.loanDetails) {
      this.loanSummaryDetailsForm.patchValue(this.loanDetails);
      this.isEditing = false;
      this.loanSummaryDetailsForm.markAsUntouched();
    }
  }

  onBuisnessTypeChange(event: Event, formControlName: string) {
    const target = event.target as HTMLSelectElement;
    const businessTypeName = this.buisnessTypes.find((businessType) => businessType.code === target.value);
    this.loanSummaryDetailsForm.get(formControlName)?.setValue(businessTypeName?.description);
    this.loanSummaryDetailsForm.get("businessType_Code")?.setValue(target.value);
  }
}
