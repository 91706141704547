export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

export function convertBase64ToArrayBuffer(base64: string): ArrayBuffer {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers).buffer;
}

export const debounce = (func: Function, wait: number) => {
  let timeout: number | undefined;
  return (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = window.setTimeout(() => func.apply(this, args), wait);
  };
}

export function convertToNumber(value: string | number): number {
  if (value === null) {
    return 0;
  }
  if (typeof value === 'string') {
    return parseFloat(value.replace(/,/g, ''));
  }
  return value;
}

import moment from 'moment';

export function formatToDate(dateString: string): string | null {

  if (!moment(dateString).isValid()) {
    return null;
  };

  const date = moment(dateString);

  return date.format('YYYY-MM-DDTHH:mm:ss');
}
