import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header/page-header.component';
import { UploadDocumentFromStorageModalComponent } from './upload-document-from-storage-modal/upload-document-from-storage-modal.component';
import { LoanHeaderDetailsComponent } from './loan-header-details/loan-header-details.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SmartPdfViewerComponent } from './smart-pdf-viewer/smart-pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChangeContext, NgxSliderModule } from 'ngx-slider-v2';


@NgModule({
  declarations: [
    PageHeaderComponent,
    UploadDocumentFromStorageModalComponent,
    LoanHeaderDetailsComponent,
    SmartPdfViewerComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    PdfViewerModule,
    NgxSliderModule,
  ],
  exports: [
    CommonModule,
    PageHeaderComponent,
    UploadDocumentFromStorageModalComponent,
    LoanHeaderDetailsComponent,
    SmartPdfViewerComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})

export class ComponentsModule { }
