import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatementSummaryState } from './summary.types';


const summaryFeature = createFeatureSelector<StatementSummaryState>('summary_feature');

export const selectDocumentByStatementIdSelector = (statementId: string) =>
    createSelector(
        summaryFeature,
        (state: StatementSummaryState) => state.documents.data[statementId]
    );
