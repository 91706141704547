import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

export interface PageActionButton {
  name: string;
  type: "primary" | "secondary";
  onClick: () => void;
  isDisabled: boolean;
}

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.css"], // Fixed typo: styleUrl -> styleUrls
})
export class PageHeaderComponent implements OnInit {
  @Input() actionButtons: PageActionButton[] = [];
  @Input() pageTitle: string = "";

  location: string = "";

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.location = this.getLocationBasedOnUrl(window.location.href);
  }

  private getLocationBasedOnUrl(currentUrl: string): string {
    if (currentUrl.includes("indemo")) {
      return "https://bsic.indemo.msuite.ai/";
    } else if (currentUrl.includes("usdemo")) {
      return "https://bsic.usdemo.msuite.ai/";
    } else if (currentUrl.includes("uat")) {
      return "https://bsic.uat.msuite.ai";
    }
    return "";
  }

  handleOnClick(action: PageActionButton) {
    if (!action.isDisabled) {
      action.onClick();
    }
  }

  trackByFn(index: number, item: PageActionButton): number {
    return index;
  }
}
